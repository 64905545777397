<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar class="dialog_toolbar" elevation="0"  v-if="only_office!=='true' || is_img">
                <div class="ma-auto ml-0">
                    <span>{{ localFile.file_name }}</span>
                    <v-btn icon @click="downloadFile(localFile)" :title="$t('download')">
                        <v-icon>mdi-download-outline</v-icon>
                    </v-btn>
                </div>
                <div>
                    <v-icon @click="closeDialog" :title="$t('close')">mdi-close</v-icon>
                </div>
            </v-toolbar>
            <v-card-text class="iframe-container-only-office py-0 px-0" v-if="only_office === 'true' && !is_img">
                <OnlyOfficeEditor
                    ref="onlyOfficeEditor"
                    :key="nameKey"
                    v-if="dialog"
                    :showDialog="dialog"
                    @closeDialogOnlyOffice="closeDialogOnlyOffice"
                    :file-type="localFile.type_mime"
                    :document-key="localFile.uuid  ?? '2558788'"
                    :document-title="localFile.file_name"
                    :document-url="localFile.url"
                    :access="localFile.access"
                    :document-model="localFile.model"
                    :editorId="nameKey"
                />
            </v-card-text>
            <v-card-text class="iframe-container py-0 px-0" v-else>
                <div v-if="isLoading" class="loading-overlay">
                    <v-progress-circular indeterminate size="80" width="7" color="primary"></v-progress-circular>
                </div>
                <iframe
                    v-show="!isLoading"
                    :src="iframeSrc"
                    ref="myIframe"
                    @load="setLoading(false)"
                    @error="setLoading(false)"
                    frameborder="0"
                    width="100%"
                    height="100%"
                ></iframe>
            </v-card-text>


        </v-card>
    </v-dialog>
</template>

<script>
import OnlyOfficeEditor from "@/components/OnlyOfficeEditor.vue";

export default {
    name: "OpenFile",
    props: {
        dialogOpenFile: {
            type: Boolean,
            required: true
        },
        file: {
            type: Object,
            required: true
        },
        nameKey: {
            type: String,
            default: 'openFile'
        }
    },
    components:{
        OnlyOfficeEditor
    },
    data() {
        return {
            only_office: process.env.VUE_APP_ONLY_OFFICE,
            dialog: false,
            localFile: {},
            iframeSrc: '',
            isLoading: true,
            officeMimeTypes: [
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
                'application/msword', // .doc
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
                'application/vnd.ms-excel', // .xls
                'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
                'application/vnd.ms-powerpoint' // .ppt
            ],
            editorKey: null,
            is_img: false,
        };
    },
    watch: {
        dialogOpenFile(newVal) {
            this.dialog = newVal;
        },
        dialog(newVal) {
            if (newVal) {
                this.editorKey = Date.now();
                this.loadFile();
            } else {
                this.clearIframe();
            }
            this.$emit('update:dialogOpenFile', newVal);
        }
    },
    methods: {
        isImg() {
            const imageTypes = [ "image/gif", "image/jpeg", "image/jpg", "image/tiff", "image/png", "image/webp", "image/bmp", "image/svg+xml", "video/mp4"];
            this.is_img = imageTypes.includes(this.localFile.type_mime);
        },
        async closeDialogOnlyOffice() {
            this.dialog = false;
            this.editorKey = null;
            this.is_img = false;
        },
        closeDialog() {
            this.dialog = false;
            this.editorKey = null;
            this.is_img = false;
        },
        clearIframe() {
            this.localFile = {};
            this.iframeSrc = '';
            this.isLoading = true;
            this.is_img = false;
        },
        setLoading(value) {
            this.isLoading = value;
        },
        async loadFile() {
            this.localFile = this.file;
            await this.isImg();
            this.isLoading = true;
            this.localFile.url = process.env.NODE_ENV === "development" && !this.is_img
                ? this.localFile.url.replace("https://", "http://")
                : this.localFile.url;
            if (this.officeMimeTypes.includes(this.file.type_mime)) {
                this.iframeSrc = `${process.env.VUE_APP_GOOGLE_DOC_VIEWER}${this.localFile.url}`;
            } else {
                this.iframeSrc = this.localFile.url;
            }

        },
        downloadFile(data) {
            fetch(data.url)
                .then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    if(data.file_name && data.file_name !== ''){
                        a.download = data.file_name;
                    }
                    else{
                        a.download = data.name;
                    }
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    this.$toastr.success(this.$t('file_download_started'));
                })
                .catch(() => {
                    this.$toastr.error(this.$t('file_download_error'));
                });
        }
    }
};
</script>

<style scoped>
.iframe-container {
    height: calc(100vh - 64px);
}
.iframe-container-only-office {
    height: calc(100vh - 0px);
}
.loading-overlay {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}
</style>
