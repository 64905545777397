<script>
import NavMixin from "@/plugins/mixins/Nav";
import {isRouteChildrenOf, isSameRouteWithQueryParams, isUrlChildrenOf} from "@/plugins/functions";

export default {
    name: "TabsNav",

    mixins: [NavMixin],

    data() {
        return {
            tabs: []
        }
    },

    props: {
        items: {
            type: Array,
            require: false,
            default: null
        },
        strict: {
            type: Boolean,
            require: false,
            default: false
        }
    },

    mounted() {
        if(this.items) {
            this.tabs = this.items
        } else {
            if(this.breadcrumbs() && this.breadcrumbs()[0]) {
                this.tabs = this.breadcrumbs()[0]['items']
            }
        }
    },

    methods: {
        isRouteChildrenOf,
        isSameRouteWithQueryParams(a, b, {
            ignoreQueryParams = false,
            ignoreParams = false,
        } = {}) {
            return isSameRouteWithQueryParams(a, b, {
                ignoreQueryParams,
                ignoreParams,
            });
        }
    }
}
</script>

<template>
    <div class="tabs-nav">
        <div class="tabs-nav__inner">
            <template v-for="(tab, key) in tabs">
                <div
                    class="tabs-nav__item"
                    :class="{
                        'active': tab.active || isSameRouteWithQueryParams(tab.route, $route, {ignoreQueryParams: !strict, ignoreParams: !strict,}) || !strict ? isRouteChildrenOf(tab.route, $route) : false
                    }"
                    :key="key"
                    v-if="(tab.permissions && can(tab.permissions)) || tab.permissions == null"
                >
                    <router-link class="tabs-nav__item-link" :to="tab.route">
                        <v-icon size="14" class="tabs-nav__item-icon" v-if="tab.icon">{{ tab.icon }}</v-icon>
                        <span class="tabs-nav__item-text">{{ $t(tab.title) }}</span>
                        <span v-if="tab.new > 0" class="tabs-nav__item-new badge">{{ tab.new }}</span>

                    </router-link>
                </div>
            </template>
        </div>
    </div>
</template>
