<template>
    <div ref="editorContainer" :id="editorId" style="height: 100vh;"></div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "OnlyOfficeEditor",
    props: {
        fileType: {
            type: String,
            required: true,
        },
        documentKey: {
            type: String,
            required: true,
        },
        documentTitle: {
            type: String,
            required: true,
        },
        documentUrl: {
            type: String,
            required: true,
        },
        mode: {
            type: String,
            default: "edit",
        },
        documentModel: {
            type: String,
            required: true,
        },
        editorId: {
            type: String,
            required: true,
        },
        access: {
            type: String,
            default: "write",
            validator: (value) => ["read", "write"].includes(value),
        },
        permissions: {
            type: Object,
            default: () => ({
                edit: true,
                download: true,
                print: true,
                comment: true,
                chat: false,
            }),
        },
    },
    computed: {
        ...mapGetters(['lang', 'userId', 'userUuid', 'userName', 'userAvatar']),
        fileExtension() {
            return this.officeMimeTypes[this.fileType] || "unknown";
        },
        computedPermissions() {
            return {
                ...this.permissions,
                edit: this.access === "write",
            };
        },
    },
    data() {
        return {
            setPayload: null,
            editor: null,
            officeMimeTypes: {
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx", // .docx
                "application/msword": "doc", // .doc
                "application/vnd.oasis.opendocument.text": "odt", // .odt
                "application/rtf": "rtf", // .rtf
                "text/plain": "txt", // .txt

                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx", // .xlsx
                "application/vnd.ms-excel": "xls", // .xls
                "application/vnd.oasis.opendocument.spreadsheet": "ods", // .ods
                "text/csv": "csv", // .csv

                "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx", // .pptx
                "application/vnd.ms-powerpoint": "ppt", // .ppt
                "application/vnd.oasis.opendocument.presentation": "odp", // .odp

                "application/pdf": "pdf", // .pdf
                "image/vnd.djvu": "djvu", // .djvu
                "application/epub+zip": "epub", // .epub
                "application/vnd.ms-xpsdocument": "xps", // .xps
                "text/html": "html", // .html
                "application/xml": "xml", // .xml
            },
        };
    },
    async mounted() {
        await this.loadApiScript()
            .then(() => {
                this.initEditor();
            })
            .catch((err) => {
                console.error("Ошибка загрузки API скрипта:", err);
            });
    },
    methods: {
        closeEditor() {
            if (this.editor) {
                try {
                    if (typeof this.editor.destroy === 'function') {
                        this.editor.destroy();
                    }
                    if (typeof this.editor.destroyEditor === 'function') {
                        this.editor.destroyEditor();
                    }
                } catch (error) {
                    console.error("Ошибка при уничтожении редактора:", error);
                }
                this.editor = null;
            }

            // Очистка контейнера редактора
            this.$nextTick(() => {
                if (this.$refs.editorContainer) {
                    this.$refs.editorContainer.innerHTML = '';
                }
            });
        },
        loadApiScript() {
            return new Promise((resolve, reject) => {
                if (window.DocsAPI) {
                    // Если скрипт уже загружен
                    resolve();
                    return;
                }
                const script = document.createElement("script");
                script.src = process.env.VUE_APP_ONLY_OFFICE_URL + "/web-apps/apps/api/documents/api.js";
                script.onload = () => resolve();
                script.onerror = () => reject(new Error("Не удалось загрузить скрипт"));
                document.head.appendChild(script);
            });
        },
        async initEditor() {
            if (!window.DocsAPI) {
                console.error("DocsAPI не найден. Проверьте загрузку скрипта.");
                return;
            }
            const lang_code = this.lang === 'kk' ? 'ru' : this.lang;

            const finalUrl = process.env.NODE_ENV === "development"
                ? this.documentUrl.replace("https://", "http://")
                : this.documentUrl;


            const setPayload = {
                events: {
                    onRequestClose: this.onRequestClose,
                    onError: (error) => {
                        console.error("Ошибка в OnlyOffice:", error);
                        alert(error.data.errorDescription);
                        this.onRequestClose(); // Закрываем редактор в случае ошибки
                    }
                },
                document: {
                    fileType: this.fileExtension,
                    key: this.documentKey + '_' + this.documentModel,
                    title: this.documentTitle,
                    url: finalUrl,
                    permissions: this.computedPermissions,
                },
                editorConfig: {
                    lang: lang_code,
                    user: {
                        id: this.userUuid,
                        name: this.userName,
                        image: this.userAvatar ?? null
                    },
                    customization: {
                        close: {
                            visible: true,
                            text: this.$t('close'), // Текст подсказки
                        },
                        anonymousEditing: false, // ОТКЛЮЧАЕМ запрос имени
                        integrationMode: "embed",
                        uiTheme: {
                            theme: "theme-light",
                            mode: "light"
                        },
                    },
                    mode: this.mode,
                    callbackUrl: process.env.VUE_APP_ONLY_OFFICE_CALLBACK_URL+"/api/v1/onlyoffice/save",
                    autosave: true, // Включаем автосохранение
                    autosaveInterval: 240 // Интервал автосохранения в секундах
                }
            }
            await this.getTokenOnlyOffice(setPayload)
        },
        async onRequestClose() {
            await this.closeEditor();
            await this.$emit('closeDialogOnlyOffice');
        },

        async getTokenOnlyOffice(setPayload) {
            try {
                var _this = this;
                this.progress = 0
                this.loading = true;
                const formData = new FormData();

                formData.append('payload', JSON.stringify({...setPayload}));

                await this.$http
                    .post(`onlyoffice/generate_token_jwt`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        if (res && res.body && res.data && res.data.token) {
                            setPayload.token = res.data.token
                            this.editor = new window.DocsAPI.DocEditor(this.editorId, setPayload);
                          //  this.editor = new window.DocsAPI.DocEditor("editorOnlyOffice", setPayload);
                        }
                    })
                    .catch(err => {
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            } catch (error) {
                console.error("Error generating token:", error);
            }
        },


    },
};

</script>

<style scoped>

</style>
