<script>
import FilterField from "@/components/FilterField.vue";
import _ from "lodash";
import {ValidationObserver} from 'vee-validate'
import {mapGetters} from "vuex";
import TextInput from "@/components/Form/TextInput.vue";
import UserSelection from "@/components/Form/UserSelection.vue";
import SelectInput from "@/components/Form/SelectInput.vue";

export default {
    name: "FilterComponent",

    components: {
        UserSelection,
        FilterField,
        // ValidationProvider,
        ValidationObserver,
        TextInput,
        SelectInput
    },

    props: {
        filterData: {
            type: Object,
            required: true
        },
        currentFilter: {
            type: Object
        },
        value: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        invalid: {
            type: Boolean,
            default: false
        },
        entity: {
            type: String,
            required: true
        },
        errors: []
    },

    data() {
        return {
            fields: {},
            filterValue: {},
            field: '',
            filterName: '',
            selectedFields: [],
            shareDialog: false,
            viewers: [],
            valid: true
        }
    },

    computed: {
        ...mapGetters(['perPageItems']),
        availableFields() {
            let availableFields = [];

            for (let i in this.filterData.fields) {
                let field = this.filterData.fields[i];

                availableFields.push({
                    text: this.$t('columns.' + field.label),
                    value: field.name,
                });
            }

            return availableFields;
        },
    },

    watch: {
        filterData: {
            handler() {
                this.fields = this.getFields();
                // this.$emit('input', this.filterValue);
            },
            deep: false
        },

        fields: {
            handler() {
                // this.$emit('input', this.getValue());
            },
            deep: true
        },

        currentFilter: {
            handler() {
                if (this.currentFilter) {
                    this.filterName = this.currentFilter.name;
                    this.selectedFields = _.uniq(this.selectedFields.concat(this.currentFilter.fields));
                }
            },
            deep: true
        },

        value: {
            handler() {
                this.selectedFields = _.uniq(this.selectedFields.concat(Object.keys(this.value)));
            },
            deep: true
        }
    },

    beforeMount() {
    },

    mounted() {
        this.$set(this, 'fields', this.getFields());

        if (this.currentFilter) {
            this.filterName = this.currentFilter.name;
            this.selectedFields = this.currentFilter.fields;
            this.viewers = this.currentFilter.viewers;
        } else {
            if (this.value) {
                this.selectedFields = Object.keys(this.value);
            }
        }
    },

    methods: {
        /**
         *  формируем массив со значениями полей фильтра {value: '', operand: ''}
         */
        getFields() {
            let fieldsValues = {};

            for (let i in this.filterData.fields) {
                let field = this.filterData.fields[i];

                if (field.operands.length === 0) {
                    continue;
                }

                let filterValue = {
                    value: '',
                    operand: _.first(field.operands)['key']
                }

                if (this.value[field.name]) {
                    let operand = _.first(_.keys(this.value[field.name]));

                    filterValue = {
                        value: this.value[field.name][operand],
                        operand: operand
                    }
                }

                fieldsValues[field.name] = filterValue;
            }

            return fieldsValues;
        },

        /**
         * формируем массив со значениями полей фильтра {value: '', operand: ''}
         */
        getValue() {
            let value = {};

            for (let i in this.fields) {
                let fieldValue = this.fields[i];
                let field = this.filterData.fields.find(f => f.name === i);

                if (
                    fieldValue.value !== undefined
                    && fieldValue.value !== null
                    && fieldValue.value !== ''
                    && this.selectedFields.indexOf(i) !== -1
                ) {
                    value[field.name] = {};
                    value[field.name][fieldValue.operand] = fieldValue.value
                }
            }

            return value;
        },

        saveFilter() {
            if (this.currentFilter) {
                this.updateFilter();
            } else {
                this.createFilter();
            }
        },

        createFilter() {
            let data = {
                name: this.filterName,
                fields: this.selectedFields,
                values: this.getValue()
            };

            this.$http.post('admin/' + this.entity + '/filter', data)
                .then(function ({data}) {
                    this.$toastr.success(this.$t('filter_saved'))
                    this.$emit('filterSaved', data);
                }).catch((error) => {
                this.$toastr.error(this.$t('filter_not_saved'))
            });
        },

        updateFilter() {
            let data = {
                name: this.filterName,
                fields: this.selectedFields,
                values: this.getValue()
            };

            this.$http.post('admin/' + this.entity + '/filter/' + this.currentFilter.id, data)
                .then(function ({data}) {
                    this.$toastr.success(this.$t('filter_saved'))
                    this.$emit('filterSaved', data);
                }).catch((error) => {
                this.$toastr.error(this.$t('filter_not_saved'))
            });
        },

        deleteField(fieldName) {
            this.selectedFields = _.without(this.selectedFields, fieldName);
            this.getValue();
        },

        submitForm() {
            let value = this.getValue();
            this.$emit('submit', value);
        },

        openShareDialog() {
            this.shareDialog = true;
        },

        closeShareDialog() {
            this.viewers = this.currentFilter.viewers
            this.shareDialog = false;
        },

        toggleShareDialog() {
            this.shareDialog = !this.shareDialog;
        },

        shareFilter() {
            let self = this;

            this.currentFilter.viewers = this.viewers;

            this.$http.post('admin/' + this.entity + '/filter/' + this.currentFilter.id, this.getData())
                .then((data) => {
                    self.closeShareDialog();
                });
        },

        getData() {
            let data = {
                name: this.filterName,
                fields: this.selectedFields,
                values: this.getValue(),
            };

            if (this.currentFilter) {
                data.viewers = this.currentFilter.viewers.map(user => user.id)
            }

            return data;
        },

        deleteFilter() {
            this.$http.delete('admin/' + this.entity + '/filter/' + this.currentFilter.id)
                .then(function () {
                    this.$toastr.success(this.$t('filter_deleted'));
                    this.$emit('filterDeleted');
                });
        },

        onOperandChange(fieldName, value) {
            this.fields[fieldName]['operand'] = value
        }
    }
}
</script>

<template>
    <v-form class="filter" @submit.prevent="submitForm()">
        <div class="filter__title mb-5">
            {{ $t('filter_title') }}
        </div>
        <ValidationObserver tag="div" ref="observer" v-slot="{ invalid, validated, passes, validate }">
            <TextInput
                v-model="filterName"
                :label="$t('filter_name')"
                :error="!valid"
                :error-messages="errors"
                class="mb-3"
                id="filter-name"
                hint="Название фильтра, которое будет отображаться в списке ваших фильтров"
            />
            <template v-for="(fieldName, key) in selectedFields">
                <template v-for="(field, k) in filterData.fields">
                    <template v-if="field.name === fieldName">
                        <FilterField
                            class="mb-3"
                            :key="key +'_'+ k"
                            v-model="fields[fieldName]['value']"
                            @changeOperand="onOperandChange(fieldName, $event)"
                            @onDeleteField="deleteField"
                            :name="fieldName"
                            :field="field"
                            :operand="fields[fieldName]['operand']"
                        />
                    </template>
                </template>
            </template>
            <div class="f-flex mb-5">
                <div
                    v-if="availableFields.length > 0"
                    class="pt-0"
                >
                    <SelectInput
                        outlined
                        solo
                        flat
                        dense
                        id="filter__select-field"
                        item-value="value"
                        item-text="text"
                        v-model="selectedFields"
                        :items="availableFields"
                        :label="$t('filter_add_field_select')"
                        multiple
                    ></SelectInput>
                </div>
            </div>
            <div class="d-flex">
                <div class="pr-5" v-if="!currentFilter || (currentFilter && currentFilter.canEdit)">
                    <v-btn small fab class="w-100" @click="saveFilter" :disabled="invalid || loading" color="primary" :title="$t('filter_save')">
                        <v-icon>mdi-content-save-check</v-icon>
                    </v-btn>
                </div>
                <div class="pr-5" v-if="currentFilter && currentFilter.canEdit">
                    <v-dialog
                        v-model="shareDialog"
                        width="500"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn fab small class="w-100" @click="openShareDialog" :disabled="invalid || loading" color="primary">
                                <v-icon>mdi-account-multiple-plus</v-icon>
                            </v-btn>
                        </template>

                        <v-card>
                            <v-card-title class="lighten-2">
                                Поделится фильтром
                            </v-card-title>

                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <UserSelection
                                            :label="$t('enter_user_name_or_email')"
                                            :error="false"
                                            id="user-id"
                                            multiple
                                            :value="viewers"
                                            @input="viewers = $event"
                                        />
                                    </v-col>
                                </v-row>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="closeShareDialog"
                                >
                                    {{ $t('cancel') }}
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    text
                                    @click="shareFilter"
                                >
                                    {{ $t('filter_save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
                <div class="pr-5" v-if="currentFilter && currentFilter.canEdit" cols="1">
                    <v-btn fab small class="w-100" @click="deleteFilter" :disabled="invalid || loading" color="primary" :title="$t('filter_delete')">
                        <v-icon>mdi-delete-forever-outline</v-icon>
                    </v-btn>
                </div>
                <div class="flex-grow-1">
                    <v-btn type="submit" :disabled="invalid || loading" class="infinity_button w-100" :block="$vuetify.breakpoint.xsOnly" color="primary">
                        {{ $t('filter_apply') }}
                        <v-icon>mdi-arrow-right-bold</v-icon>
                    </v-btn>
                </div>
            </div>
        </ValidationObserver>
    </v-form>
</template>

<style scoped lang="scss">
.filter {
    background-color: #fff;
    padding: $grid-gutter;
    border-radius: $border-radius-root;
}

.filter__title {
    font-size: 20px;
}
</style>
