<template>
    <div>
        <template v-if="false">
            <v-list-item v-for="(langItem, index) in languageItems"
                         :key="index"
                         @click="$emit('changeLanguage', langItem.code, true)"
                         style="min-height: 30px">
                <v-list-item-content class="title_subtitle py-1">
                    <v-list-item-title :class="{ active: lang === langItem.code }">
                        {{ $t(langItem.label) }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>


        <v-list-item v-for="(item, i) in menuClientItems" :key="i" v-show="item.visible"
                     @click.native="item.action ? item.action() : false"
                     href="javascript:void(0)"
                     :to="item.to" ripple
                     :exact="item.exact !== undefined ? item.exact : true"
                     style="min-height: 30px">
            <v-list-item-content class="title_subtitle py-1">
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "MenuListItem",
    data() {
        return {
            languageItems: [
                {code: 'kk', label: 'kazakh'},
                {code: 'ru', label: 'russian'},
                {code: 'en', label: 'english'},
                {code: 'zh', label: 'chinese'}
            ],
            menuClientItems: [],
        };
    },
    computed: {
        ...mapGetters([
            'lang',
            'WorkDayStatusId',
            'WorkDayStart',
            'businessDayEntrance'
        ])
    },

    async mounted() {
        await this.menuClientNavigation();
    },
    methods: {
        menuClientNavigation() {
            this.menuClientItems = [
                {
                    title: 'profile',
                    icon: "mdi-account",
                    to: {
                        name: 'profile'
                    },
                    visible: this.$auth.check(),
                    action: this.forceRerender,
                    exact: false
                },
                {
                    title: 'logout',
                    icon: "mdi-logout-variant",
                    visible: this.$auth.check(),
                    action: this.logout
                }
            ]
        },
        async logout() {
            await this.$auth
                .logout({
                    makeRequest: true,
                    redirect: {
                        name: "login"
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('you_have_successfully_logged_out'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('error_occurred_while_exiting'))
                })
        },
    }
}
</script>

<style scoped>

</style>
